import React from 'react'
import { getDaysInPart } from '../utils'

function RowWorker({ worker, tab, statuses, isMobile, currentMonthDays, localSchedule, handleStatusChange }) {

    const workerName = worker.name == '' ? worker.firstName + ' ' + worker.secondName + ' ' + worker.thirdName : worker.name;

    return <tr key={worker.id}>
        <td>{workerName}</td>
        {getDaysInPart(
            tab.eventKey,
            currentMonthDays,
            isMobile
        ).map((day, i) => (
            <td key={i}>
                <select
                    value={
                        localSchedule
                            ?.find(s => s.workerId === worker.id)
                            ?.schedule.find(d => d.day === day)?.status
                    }
                    onChange={e =>
                        handleStatusChange(worker.id, day, e.target.value)
                    }
                >
                    {statuses.map(status => (
                        <option key={status.key} value={status.key}>
                            {status.char}
                        </option>
                    ))}
                </select>
            </td>
        ))}
    </tr>
}

export default RowWorker
