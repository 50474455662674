import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { HttpData } from '../../../Core'

export default function Create({
	show,
	jwt,
	onHide,
	workerCategories,
	workerTypes,
	mathTimeTypes,
	employmentTypes,
	mathSalaryTypes,
	qualificationTypes,
	onCreated,
}) {
	const [workerData, setWorkerData] = useState({
		categoryId: workerCategories.length > 0 ? workerCategories[0].id : 0,
		typeId: workerTypes.length > 0 ? workerTypes[0].id : 0,
		mathSalaryId: mathSalaryTypes.length > 0 ? mathSalaryTypes[0].id : 0,
		employmentTypeId: employmentTypes.length > 0 ? employmentTypes[0].id : 0,
		mathTime: mathTimeTypes.length > 0 ? mathTimeTypes[0].id : 0,
		qualificationId:
			qualificationTypes.length > 0 ? qualificationTypes[0].id : 0,
	})

	const createSubmit = async e => {
		e.preventDefault()

		const response = await HttpData('/api/v1/Workers', 'POST', jwt, {
			...workerData,
			zone: workerData.zone + ':00',
		})

		onCreated(response.data)
	}

	return (
		<Modal
			show={show}
			onHide={() => onHide(false)}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Form onSubmit={e => createSubmit(e)}>
				<Modal.Header closeButton>
					<Modal.Title>Создать</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>
							<b>Фамилия</b>
						</Form.Label>
						<Form.Control
							required
							className='mb-2'
							type='string'
							value={workerData.secondName}
							onChange={e => {
								setWorkerData({
									...workerData,
									secondName: e.target.value,
								})
							}}
						/>
						<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
					</Form.Group>

					<Form.Group>
						<Form.Label>
							<b>Имя</b>
						</Form.Label>
						<Form.Control
							required
							className='mb-2'
							type='string'
							value={workerData.firstName}
							onChange={e => {
								setWorkerData({
									...workerData,
									firstName: e.target.value,
								})
							}}
						/>
						<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
					</Form.Group>

					<Form.Group>
						<Form.Label>
							<b>Отчество</b>
						</Form.Label>
						<Form.Control
							className='mb-2'
							type='string'
							value={workerData.thirdName}
							onChange={e => {
								setWorkerData({
									...workerData,
									thirdName: e.target.value,
								})
							}}
						/>
						<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
					</Form.Group>

					<Form.Group>
						<Form.Label>
							<b>Логин</b>
						</Form.Label>
						<Form.Control
							required
							className='mb-2'
							type='string'
							value={workerData.login}
							onChange={e => {
								setWorkerData({
									...workerData,
									login: e.target.value,
								})
							}}
						/>
						<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
					</Form.Group>

					<Form.Group>
						<Form.Label>
							<b>Пароль</b>
						</Form.Label>
						<Form.Control
							required
							className='mb-2'
							type='string'
							value={workerData.password}
							onChange={e => {
								setWorkerData({
									...workerData,
									password: e.target.value,
								})
							}}
						/>
						<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
					</Form.Group>

					<Form.Group>
						<Form.Label>
							<b>Номер телефона</b>
						</Form.Label>
						<Form.Control
							required
							type='tel'
							value={workerData.phone}
							onChange={e => {
								setWorkerData({
									...workerData,
									phone: e.target.value,
								})
							}}
						/>
						<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
					</Form.Group>

					<Form.Group>
						<Form.Label>Категория</Form.Label>
						<Form.Select
							value={workerData.categoryId}
							required
							onChange={e => {
								setWorkerData({
									...workerData,
									categoryId: e.target.value,
								})
							}}
						>
							{workerCategories && workerCategories.length > 0
								? workerCategories.map(workerCategory => (
										<option key={workerCategory.id} value={workerCategory.id}>
											{workerCategory.name}
										</option>
								  ))
								: ''}
						</Form.Select>
					</Form.Group>

					<Form.Group>
						<Form.Label>Тип работника</Form.Label>
						<Form.Select
							value={workerData.typeId}
							required
							onChange={e => {
								setWorkerData({ ...workerData, typeId: e.target.value })
							}}
						>
							{workerTypes && workerTypes.length > 0
								? workerTypes.map(workerType => (
										<option key={workerType.id} value={workerType.id}>
											{workerType.name}
										</option>
								  ))
								: ''}
						</Form.Select>
					</Form.Group>

					<Form.Group>
						<Form.Label>Тип расчета</Form.Label>
						<Form.Select
							value={workerData.mathSalaryId}
							required
							onChange={e => {
								setWorkerData({
									...workerData,
									mathSalaryId: e.target.value,
								})
							}}
						>
							{mathSalaryTypes && mathSalaryTypes.length > 0
								? mathSalaryTypes.map(mathSalaryType => (
										<option key={mathSalaryType.id} value={mathSalaryType.id}>
											{mathSalaryType.name}
										</option>
								  ))
								: ''}
						</Form.Select>
					</Form.Group>

					<Form.Group>
						<Form.Label>Тип трудоустройства</Form.Label>
						<Form.Select
							style={{ display: 'inline-block' }}
							value={workerData.employmentTypeId}
							required
							onChange={e => {
								setWorkerData({
									...workerData,
									employmentTypeId: e.target.value,
								})
							}}
						>
							{employmentTypes && employmentTypes.length > 0
								? employmentTypes.map(employmentType => (
										<option key={employmentType.id} value={employmentType.id}>
											{employmentType.name}
										</option>
								  ))
								: ''}
						</Form.Select>
					</Form.Group>

					<Form.Group>
						<Form.Label>График работы</Form.Label>
						<Form.Select
							value={workerData.mathTime}
							required
							onChange={e => {
								setWorkerData({
									...workerData,
									mathTime: e.target.value,
								})
							}}
						>
							{mathTimeTypes && mathTimeTypes.length > 0
								? mathTimeTypes.map(mathTimeType => (
										<option key={mathTimeType.id} value={mathTimeType.id}>
											{mathTimeType.name}
										</option>
								  ))
								: ''}
						</Form.Select>
					</Form.Group>

					<Form.Group>
						<Form.Label>Квалификация</Form.Label>
						<Form.Select
							value={workerData.qualificationId}
							required
							onChange={e => {
								setWorkerData({
									...workerData,
									qualificationId: e.target.value,
								})
							}}
						>
							{qualificationTypes && qualificationTypes.length > 0
								? qualificationTypes.map(qualificationType => (
										<option
											key={qualificationType.id}
											value={qualificationType.id}
										>
											{qualificationType.name}
										</option>
								  ))
								: ''}
						</Form.Select>
					</Form.Group>

					<Form.Group>
						<Form.Label>Часовой пояс</Form.Label>
						<Form.Control
							type='time'
							value={workerData.zone}
							required
							onChange={e =>
								setWorkerData({ ...workerData, zone: e.target.value })
							}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={() => onHide(false)}>
						Отмена
					</Button>
					<Button variant='success' type='submit'>
						Создать
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}
