import React, { useEffect, useState } from "react";
import { Accordion, Alert, Badge, Button, Stack } from "react-bootstrap";
import styles from './style.module.css';
import { TiPlus } from "react-icons/ti";
import EditProduct from "./ProductCRUD/edit";
import CreateProduct from "./ProductCRUD/create";
import CreateCategory from "./CategoryCRUD/create";
import { deleteProduct, getAmounts, getCategories, getProducts } from "./productWarehouseApi";
import { getProducts as getManagerProducts } from "../../../apiMethod/products";
import { getExtraWorks } from "../../../apiMethod/extraWorks";
import CreatePull from "./ProductCRUD/createPull";

function Main({ jwt }) {

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    const [isCreateProduct, setIsCreateProduct] = useState(false);
    const [isCreateCategory, setIsCreateCategory] = useState(false);

    const [currentCategoryId, setCurrentCategoryId] = useState(null);

    const [workerExtraWorks, setWorkerExtraWorks] = useState([]);
    const [managerProducts, setManagerProducts] = useState([]);

    useEffect(() => {

        async function didMount(){

            const responseProducts = await getProducts();
            const responseAmounts = await getAmounts();
            const responseCategories = await getCategories();
            const responseExtraWorks = await getExtraWorks(jwt);
            const responseManagerProducts = await getManagerProducts(jwt);

            setCategories([...responseCategories, {
                "id": null,
                "name": "Без категории"
            }]);

            setProducts(responseProducts.map(o => {
                o.sum = responseAmounts.find(f => f.productId == o.id).sum;
                return o;
            }));
            setWorkerExtraWorks(responseExtraWorks);
            setManagerProducts(responseManagerProducts);
        }

        didMount();

    }, []);

    const deleteProductPreview = async(id) => {

        if(!window.confirm("Вы точно хотите удалить?"))
            return;

        if(!await deleteProduct(id))
        {
            alert('К сожалению не получилось удалить');
            return;
        }

        setProducts([...products.filter(o => o.id != id)]);
    }

    const ProductItemView = ({ product }) => {

        const [isEdit, setIsEdit] = useState(false);
        const [isPull, setIsPull] = useState(false);

        return <React.Fragment>
            <Accordion.Item eventKey={product.id}>
            <Accordion.Header>
                <div className={styles.accordionHeader}>
                    {product.name}
                    <div>
                        <Badge className={styles.badge}>Коефф: {product.coefficient}</Badge>
                        <Badge className={styles.badge}>Кол-во: {product.sum}</Badge>
                    </div>

                </div>
            </Accordion.Header>
            <Accordion.Body className={styles.buttonsEvent}>
                <Button className={styles.buttonEvent} onClick={() => setIsPull(true)} variant="warning">Кол-во</Button>
                <Button className={styles.buttonEvent} onClick={() => setIsEdit(true)} variant="warning">Изменить</Button>
                <Button className={styles.buttonEvent} onClick={() => deleteProductPreview(product.id)} variant="danger">Удалить</Button>
            </Accordion.Body>
        </Accordion.Item>
        {
            isEdit ? <EditProduct product={product} 
                                jwt={jwt}
                                workerExtraWorks={workerExtraWorks} 
                                managerProducts={managerProducts} 
                                categories={categories} 
                                onUpdate={(u) => setProducts([...products.filter(o => o.id != u.id), u]) || setIsEdit(false)} 
                                show={isEdit} 
                                onHide={() => setIsEdit(false)}/> : ''
        }
        {
            isPull ? <CreatePull id={product.id} 
                            show={isPull} 
                            onHide={() => setIsPull(false)} 
                            onUpdate={(c) => setProducts([...products.filter(o => o.id != product.id), { ...product, sum: +product.sum + +c}]) || setIsPull(false)}/> : ''
        }
        </React.Fragment>
    };

    const CategoryItemView = ({ category }) => {

        const onClick = () => {
            setCurrentCategoryId(category.id);
        }

        return <Button onClick={onClick} variant={currentCategoryId == category.id ? "outline-primary": "primary"} className={styles.buttonCategory}>{category.name}</Button>
    }

    const productsView = products && products.length > 0 ? <div>
        <Accordion>{products.filter(o => o.categoryId == currentCategoryId).map(o => <ProductItemView key={o.id} product={o} />)}</Accordion>
    </div> :
        <Alert variant="warning">К сожалению сейчас нет продукции</Alert>;

    const categoriesView = categories && categories.length > 0 ? <div className={styles.categories}>
        { categories.map(o => <CategoryItemView key={o.id} category={o}/>) }
    </div> :
        <Alert variant="warning">К сожалению сейчас нет категорий</Alert>;

    return <div className={styles.container}>
        <div className={styles.content}>
            <Stack className={styles.buttonsUpper} direction="horizontal" gap={3}>
                <Button onClick={() => setIsCreateProduct(true)} variant="success"><TiPlus /> продукцию</Button>
                <Button onClick={() => setIsCreateCategory(true)} variant="success"><TiPlus /> категорию</Button>
            </Stack>

            <Stack gap={1}>
                {categoriesView}
                {productsView}
            </Stack>
        </div>
        {
            isCreateProduct ? 
            <CreateProduct product={ {
                "name": '',
                "categoryId": null,
                "workerExtraWorkdId": null,
                "managerProductId": null,
                "coefficient": 1,
            }} 
            jwt={jwt}
            categories={categories} 
            managerProducts={managerProducts} 
            onHide={() => setIsCreateProduct(false)} 
            onCreated={(n) => setProducts([...products, { ...n, sum: 0 }]) || setIsCreateProduct(false)}
            show={isCreateProduct} 
            workerExtraWorks={workerExtraWorks}/> : ''
        }
        {
            isCreateCategory ? 
            <CreateCategory show={isCreateCategory} onHide={() => setIsCreateCategory(false)} 
            onCreated={(c) => setCategories([...categories, c]) || setIsCreateCategory(false)}/>: ''
        }
    </div>
}

export default Main;