import { useEffect, useState } from "react"
import { getQualifications, updateQualification } from "./SalaryAPI";
import { Alert, Button, Form } from "react-bootstrap";
import { GrUpdate } from "react-icons/gr";

export default function Qualification({ jwt }){

    const [qualifications, setQualifications] = useState([]);

    useEffect(() => {

        async function didMount() {
            
            const response = await getQualifications(jwt);

            setQualifications(response ?? []);
        }

        didMount();
    }, []);

    const Item = ({ item }) => {

        const[value, setValue] = useState(item.value);

        const updateValue = async(newValue) => {

            const isUpdate = await updateQualification(jwt, item.id, newValue);

            if(isUpdate)
            {
                item.value = newValue;
                setValue(newValue);
            }
            else alert("К сожалению что-то пошло не так");
        }

        return <Form.Group>
            <Form.Label>{item.name}</Form.Label>
            <Form.Control
                required
                type='number'
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            {
                item.value != value ? 
                <Button onClick={() => updateValue(value)}><GrUpdate/></Button>:''
            }
        </Form.Group>
    }

    return <div>
        <h3>Квалификации</h3>
        {
            qualifications && qualifications.length > 0 ?
            qualifications.map(o => <Item item={o}/>): 
            <Alert variant="warning">К сожалению здесь ничего нет</Alert>
        }
    </div>
}