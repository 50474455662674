import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import { createManagerProduct, createWorkerExtraWork, updateProduct } from "../productWarehouseApi";
import CreateManagerProductView from "../createManagerProductView";
import CreateWorkerExtraWorkView from "../createWorkerExtraWork";


export default function Edit({ jwt, product, categories, workerExtraWorks, managerProducts, onUpdate, show, onHide }) {

    const [localProduct, setLocalProduct] = useState(product);

    const [localExtraWorks, setLocalExtraWorks] = useState([...workerExtraWorks]);
    const [localProducts, setLocalProducts] = useState([...managerProducts]);

    const [isCreateWorkerExtraWork, setIsCreateWorkerExtraWork] = useState(false);
    const [isCreateManagerProduct, setIsCreateManagerProduct] = useState(false);

    const submitProduct = async(e) => {
        e.preventDefault();

        const updateItem = {
            id: localProduct.id,
            name: localProduct.name,
            coefficient: localProduct.coefficient,
            productId: localProduct.managerProductId,
            extraWorkId: localProduct.workerExtraWorkdId,
            categoryId: localProduct.categoryId,
            sum: product.sum
        };
        const response = await updateProduct(updateItem);

        if(response)
            onUpdate(updateItem);
        else alert('Что-то пошло не по плану, обратитесь в поддержку');
    }

    if(isCreateWorkerExtraWork)
        return <CreateWorkerExtraWorkView jwt={jwt}
                                        onCreated={(n) => {
                                            setLocalProduct({ ...localProduct, workerExtraWorkdId: n.id});
                                            setLocalExtraWorks([...localExtraWorks, n]);
                                            workerExtraWorks.push(n);
                                            setIsCreateWorkerExtraWork(false);
                                        }}
                                        onHide={() => setIsCreateWorkerExtraWork(false)}
                                        show={isCreateWorkerExtraWork}
                                        name={categories.find(c => c.id == localProduct.categoryId).name + ' ' + localProduct.name}/>

    if(isCreateManagerProduct)
        return <CreateManagerProductView jwt={jwt} 
                                        onCreated={(n) =>  {
                                            setLocalProduct({ ...localProduct, managerProductId: n.id});
                                            setLocalProducts([...localProducts, n]);
                                            managerProducts.push(n);
                                            setIsCreateManagerProduct(false);}}
                                        onHide={() => setIsCreateManagerProduct(false)}
                                        show={isCreateManagerProduct}
                                        name={categories.find(c => c.id == localProduct.categoryId).name + ' ' + localProduct.name}/>

    return <Modal show={show} onHide={onHide} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Form onSubmit={(e) => submitProduct(e)}>
        <Modal.Header closeButton>
            <Modal.Title>{product.name} №{product.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Продукция 1"
                        required
                        value={localProduct.name}
                        onChange={(e) => setLocalProduct({ ...localProduct, name: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                    <Form.Label>Коэффициент</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="1"
                        value={localProduct.coefficient}
                        onChange={(e) => setLocalProduct({ ...localProduct, coefficient: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                    <Form.Label>Категория</Form.Label>
                    <Form.Select aria-label="Default select categories" value={localProduct.categoryId ?? -1} onChange={(e) => setLocalProduct({ ...localProduct, categoryId: e.target.value == -1 ? null : e.target.value })}>
                        {
                            categories.map(o => <option key={o.id} value={o.id ?? -1}>{o.name}</option>)
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                    <Form.Label>Работа</Form.Label>
                    <InputGroup>
                    <Form.Select aria-label="Default select worker Extra Work Id" value={localProduct.workerExtraWorkdId ?? -1} onChange={(e) => setLocalProduct({ ...localProduct, workerExtraWorkdId: e.target.value == -1 ? null : e.target.value })}>
                        {
                            localExtraWorks.findIndex(o => o.id == localProduct.workerExtraWorkdId) > -1 ?
                            localExtraWorks.map(o => <option key={o.id} value={o.id ?? -1}>{o.name}</option>) :
                            [...localExtraWorks.map(o => <option key={o.id} value={o.id ?? -1}>{o.name}</option>), <option key={localProduct.workerExtraWorkdId} value={localProduct.workerExtraWorkdId ?? -1}>Удалено</option>]
                        }
                    </Form.Select>
                    <Button onClick={() => setIsCreateWorkerExtraWork(true)}><TiPlus/></Button>
                    </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput42">
                    <Form.Label>Отправки</Form.Label>
                    <InputGroup>
                    <Form.Select aria-label="Default select worker Extra Work Id" value={localProduct.managerProductId ?? -1} onChange={(e) => setLocalProduct({ ...localProduct, managerProductId: e.target.value == -1 ? null : e.target.value })}>
                        {
                            localProducts.findIndex(o => o.id == localProduct.managerProductId) > -1 ?
                            localProducts.map(o => <option key={o.id} value={o.id ?? -1}>{o.name}</option>) : 
                            [...localProducts.map(o => <option key={o.id} value={o.id ?? -1}>{o.name}</option>), <option key={localProduct.managerProductId} value={localProduct.managerProductId ?? -1}>Удалено</option>]
                        }
                    </Form.Select>
                    <Button onClick={() => setIsCreateManagerProduct(true)}><TiPlus/></Button>
                    </InputGroup>
                </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="success" type="submit">Сохранить</Button>
        </Modal.Footer>
            </Form>
    </Modal>
}