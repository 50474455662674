import React, { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styles from '../Style/home.module.css';
import { Alert, Spinner, Table } from 'react-bootstrap';
import { getMeAnalyze } from '../../../apiMethod/Employee/metaShifts';
import { HttpNoData } from '../../../Core';

function Home({ jwt }) {

    const[notifications, setNotifications] = useState([]);
    const[settingMainPage, setSettingMainPage] = useState({});
    const[salary, setSalary] = useState({});

  const [data, setData] = useState();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [analytic, setAnalytic] = useState({
    sumPoints: 0,
    simplePoints: 0,
    middlePoints: 0,
    highPoints: 0,
    upPoints: 0,
    sumHours: 0,
    dayHours: 0,
    nightHours: 0,
    dayOffHours: 0,
    extraWorkPoints: 0,
    daysOff: 0,
    isMachines: 0,
    machinePoints: 0,
    machinePercent: 0,
  });

  const getVariant = (number) => {

    if(number == 0)
        return 'info';

    if(number == 1)
        return 'warning';

    if(number == 2)
        return 'danger';

    return 'warning';
}

  useEffect(() => {

    async function DidMount() {
      const response = await getMeAnalyze(jwt);

      if(!response)
        return;

      const machineAverage = [];
      for (let i = 0; i < response.length; i++) {
        const element = response[i];

        const dayHours = new Date(`2024-12-12T${element.day}`);
        element.dayHours = dayHours.getHours() + dayHours.getMinutes() / 60;

        const nightHours = new Date(`2024-12-12T${element.night}`);
        element.nightHours = nightHours.getHours() + nightHours.getMinutes() / 60;

        const dayOffHours = new Date(`2024-12-12T${element.dayOff}`);
        element.dayOffHours = dayOffHours.getHours() + dayOffHours.getMinutes() / 60;

        analytic.sumPoints += element.sumPoints;
        analytic.middlePoints += element.middlePoints;
        analytic.simplePoints += element.simplePoints;
        analytic.highPoints += element.highPoints;
        analytic.upPoints += element.upPoints;
        analytic.dayHours += element.dayHours;
        analytic.nightHours += element.nightHours;
        analytic.dayOffHours += element.dayOffHours;
        analytic.extraWorkPoints += element.extraWorkPoints;
        analytic.daysOff += element.isDayOff;

        if (element.isMachines) {
          analytic.isMachines += element.isMachines;
          analytic.machinePoints += element.machinePoints;
          machineAverage.push(element.machinePercent);
        }
      }

      analytic.machinePercent = machineAverage.reduce((a, b) => a + b, 0) / machineAverage.length;
      analytic.sumHours = analytic.dayHours + analytic.nightHours + analytic.dayOffHours;
      setAnalytic({ ...analytic })
      setData(response);
    }

    DidMount();
  }, []);

  useEffect(() => {
        
    async function didMount() {
             const responseNotifications = await HttpNoData(`/api/v1/notification/${'mainPage'}`, 'GET', jwt);
             const responseSalary = await HttpNoData(`api/v1/salary`, 'GET', jwt);
             const responseSettings = await HttpNoData(`api/v1/workers/settingMainPage`, 'GET', jwt);

             if(responseSettings.statusSuccessful)
                 setSettingMainPage(responseSettings.data);

             if(responseSalary.statusSuccessful)
                 setSalary(responseSalary.data);
 
             if(responseNotifications.statusSuccessful)
                 setNotifications(responseNotifications.data);
    }

    didMount();
 }, []);


  window.addEventListener('resize', async function (event) {
    setIsMobile(window.innerWidth <= 640);
  }, true);

  if (data == null)
    return <div className={styles.body_load}>
      <Spinner animation="grow" role="status" />
    </div>

  const COLORS = ['#FFBB28', '#0088FE', '#00C49F', '#FF8042'];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.38;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text fontSize={12} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  const status = analytic.sumPoints / analytic.sumHours;

  const RechartCircle = ({ title, valueTitle, data }) => {

    return <div className={styles.itemRechart}>
      <div className={styles.titleItem}>{title} - {valueTitle}</div>
      <ResponsiveContainer className={styles.rechart}>
        <PieChart width={'100%'} height={'100%'}>
          <Pie
            data={data}
            cx={isMobile ? '30%' : '50%'}
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>

          {
            isMobile ?
              <Legend formatter={(v, e, i) => `${v} - ${data[i].value}`} layout="vetical" verticalAlign="middle" align="right" /> :
              <Legend formatter={(v, e, i) => `${v} - ${data[i].value}`} />
          }
        </PieChart>
      </ResponsiveContainer>
    </div>
  }

  const bodyPoints = {
      valueTitle: data.map(o => o.sumPoints),
      simplePoints: data.map(o => o.simplePoints),
      middlePoints: data.map(o => o.middlePoints),
      highPoints: data.map(o => o.highPoints),
      upPoints: data.map(o => o.upPoints),
  };

  const bodyHours = {
    valueTitle: data.map(o => o.dayHours + o.nightHours + o.dayOffHours),
    day: data.map(o => o.dayHours),
    night:  data.map(o => o.nightHours),
    dayOff:  data.map(o => o.dayOffHours),
};

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.title}>Общая динамика</div>
        <div className={styles.elements}>
          <div className={styles.rechartContent}>

            <RechartCircle title={'Баллы'} valueTitle={+(bodyPoints.valueTitle.length > 0 ? bodyPoints.valueTitle : [0]).reduce((a, b) => a + b).toFixed(2)} data={[
              {
                name: "Простые",
                value: +(bodyPoints.simplePoints.length > 0 ? bodyPoints.simplePoints: [0]).reduce((a, b) => a + b).toFixed(2)
              },
              {
                name: "Средние",
                value: +(bodyPoints.middlePoints.length > 0 ? bodyPoints.middlePoints: [0]).reduce((a, b) => a + b).toFixed(2)
              },
              {
                name: "Сложные",
                value: +(bodyPoints.highPoints.length > 0 ? bodyPoints.highPoints: [0]).reduce((a, b) => a + b).toFixed(2)
              },
              {
                name: "С надбавкой",
                value: +(bodyPoints.upPoints.length > 0 ? bodyPoints.upPoints: [0]).reduce((a, b) => a + b).toFixed(2)
              }
            ]} />

            <RechartCircle title={'Часы'} valueTitle={+(bodyHours.valueTitle.length > 0 ? bodyHours.valueTitle: [0]).reduce((a, b) => a + b).toFixed(2)} data={[
              {
                name: "Дневные",
                value: +(bodyHours.day.length > 0 ? bodyHours.day: [0]).reduce((a, b) => a + b).toFixed(2)
              },
              {
                name: "Ночные",
                value: +(bodyHours.night.length > 0 ? bodyHours.night: [0]).reduce((a, b) => a + b).toFixed(2)
              },
              {
                name: "Выходные",
                value: +(bodyHours.dayOff.length > 0 ? bodyHours.dayOff: [0]).reduce((a, b) => a + b).toFixed(2)
              }
            ]} />

          </div>
          <div className={styles.textContent}>
            <label className={styles.labelTitle}>КПД (Баллы/Часы) - {status.toFixed(2)}</label>
            <label className={styles.labelTitle}>Среднее кол-во баллов за смену - { (data.length > 0 ? (analytic.sumPoints / data.length) : 0).toFixed(2)}</label>
            <label className={styles.labelTitle}>Средняя длительность смены - { (data.length > 0 ? (analytic.sumHours / data.length): 0).toFixed(2)}</label>
            {
              analytic.isMachines ?
                <div className={styles.item}>
                  <div className={styles.footerText}>
                    <div className={styles.labelTitle} style={{fontSize: 24}}>Лазера</div>
                    <div className={styles.labelTitle}>Сред. нагрузка - {analytic.machinePercent}%</div>
                    <div className={styles.labelTitle}>Баллы - {analytic.machinePoints}</div>
                  </div>
                </div>
                : null
            }
          </div>
        </div>
        {
                    notifications && notifications.length > 0 ?
                    notifications.map(n => <Alert key={n.id} variant={getVariant(n.priority)}>{n.message}</Alert>): ''
            }
            {
                (settingMainPage.isPremium || settingMainPage.isFines || settingMainPage.isSalary || settingMainPage.isKpd) ?
                <Table striped bordered hover style={{maxWidth: 1440}}>
                <thead>
                  <tr>
                    { settingMainPage.isPremium ? <th>Премия</th> : ''}
                    { settingMainPage.isFines ? <th>Штрафы</th> : ''}
                    { settingMainPage.isSalary ? <th>Зарплата</th> : ''}
                    { settingMainPage.isKpd ? <th>КПД</th> : ''}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    { settingMainPage.isPremium ? <th>{salary.premium}</th> : ''}
                    { settingMainPage.isFines ? <th>{salary.fines}</th> : ''}
                    { settingMainPage.isSalary ? <th>{salary.salary}</th> : ''}
                    { settingMainPage.isKpd ? <th>{salary.kpd}%</th> : ''}
                  </tr>
                </tbody>
              </Table>
                : ''
            }
      </div>
    </div>
  );
}
export default Home;
