export function getDaysInMonth(date) {
	const dateObj = new Date(date)
	const currentMonth = dateObj.getMonth()
	const currentYear = dateObj.getFullYear()
	const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()
	return daysInMonth
}

export function getCharByStatus(status, statuses) {
	const statusObj = statuses.find(s => s.key === status)
	return statusObj ? statusObj.char : ''
}

export function getWorkerById(workerId, workers) {
	const workerObj = workers.find(worker => worker.id === workerId)
	return workerObj
		? `${workerObj.secondName} ${workerObj.firstName} ${workerObj.thirdName}`
		: ''
}

export function getCurrentDate() {
	const now = new Date()
	const year = now.getFullYear()
	const month = (now.getMonth() + 1).toString().padStart(2, '0')
	const day = now.getDate().toString().padStart(2, '0')

	return `${year}-${month}-${day}`
}

export function getDaysInPart(part, totalDays, isMobile) {
	const daysInPart = isMobile ? 4 : Math.ceil(totalDays / 2)
	const startDay = (part - 1) * daysInPart + 1
	const endDay = Math.min(part * daysInPart, totalDays)
	return Array(endDay - startDay + 1)
		.fill()
		.map((_, i) => startDay + i)
}