import React, { useEffect, useMemo, useState } from 'react'
import { Button, Container, ListGroup } from 'react-bootstrap'
import DesktopTable from './DesktopTable'
import MobileTable from './MobileTable'
import {
	deleteWorker,
	getWorkerCategories,
	getWorkerEmploymentTypes,
	getWorkerMathSalary,
	getWorkerMathTime,
	getWorkerQualificationTypes,
	getWorkers,
	getWorkerTypes,
	updateWorker,
} from './WorkersAPI'
import Create from './CreateWorker'

const Workers = ({ jwt }) => {
	const mobileSize = 1200
	const [workers, setWorkers] = useState([])
	const [workerCategories, setWorkerCategories] = useState([])
	const [workerTypes, setWorkerTypes] = useState([])
	const [workerMathSalary, setWorkerMathSalary] = useState([])
	const [workerEmploymentTypes, setWorkerEmploymentTypes] = useState([])
	const [workerMathTime, setWorkerMathTime] = useState([])
	const [workerQualification, setWorkerQualification] = useState([]);
	const [isMobile, setIsMobile] = useState(window.screen.width - mobileSize <= 0);
	const [isCreateWorker, setIsCreateWorker] = useState(false);

	useEffect(() => {
		async function didMount() {
			setWorkers((await getWorkers(jwt)) ?? [])
			setWorkerCategories((await getWorkerCategories(jwt)) ?? [])
			setWorkerTypes((await getWorkerTypes(jwt)) ?? [])
			setWorkerMathSalary((await getWorkerMathSalary(jwt)) ?? [])
			setWorkerEmploymentTypes(
				(await getWorkerEmploymentTypes(jwt)) ?? []
			)
			setWorkerMathTime((await getWorkerMathTime(jwt)) ?? [])
			setWorkerQualification((await getWorkerQualificationTypes(jwt)) ?? []);
		}
		didMount()

		setIsMobile(document.body.clientWidth - mobileSize <= 0);
		window.addEventListener('resize', function (event) {
			setIsMobile(document.body.clientWidth - mobileSize <= 0);
		})
	}, [])

	const handleWorkerDelete = id => {
		setWorkers(workers.filter(worker => worker.id !== id))
	}

	const Content = () => {
		if (isMobile) {
			return (
				<Container>
					<h1 style={{ textAlign: 'center' }}>Сотрудники цеха:</h1>
					<Button onClick={() => setIsCreateWorker(true)}>Добавить</Button>
					<ListGroup>
						{workers && workers.length > 0
							? workers.map(worker => (
									<MobileTable key={worker.id} 
									deleteWorker={deleteWorker} 
									employmentTypes={workerEmploymentTypes}
									handleDelete={handleWorkerDelete}
									jwt={jwt}
									mathSalaryTypes={workerMathSalary}
									mathTimeTypes={workerMathTime}
									qualificationTypes={workerQualification}
									updateWorker={updateWorker}
									worker={worker}
									workerCategories={workerCategories}
									workerTypes={workerTypes}
									/>
							  ))
							: 'Всех уволили что-ли?'}
					</ListGroup>
				</Container>
			)
		}
		return (
			<Container
				style={{
					maxWidth: '1440px',
					margin: '40px auto',
				}}
			>
				<h1 style={{ textAlign: 'center' }}>Сотрудники цеха:</h1>
				<Button onClick={() => setIsCreateWorker(true)}>Добавить</Button>
				<ListGroup>
					{workers && workers.length > 0
						? workers.map(worker => (
								<DesktopTable key={worker.id} 
								 deleteWorker={deleteWorker} 
								 employmentTypes={workerEmploymentTypes}
								 handleDelete={handleWorkerDelete}
								 jwt={jwt}
								 mathSalaryTypes={workerMathSalary}
								 mathTimeTypes={workerMathTime}
								 qualificationTypes={workerQualification}
								 updateWorker={updateWorker}
								 worker={worker}
								 workerCategories={workerCategories}
								 workerTypes={workerTypes}
								 />
						  ))
						: 'Всех уволили что-ли?'}
				</ListGroup>
			</Container>
		)
	}

	return <React.Fragment>
		<Content/>
		{
			isCreateWorker ?
			<Create show={isCreateWorker}
				employmentTypes={workerEmploymentTypes}
				mathSalaryTypes={workerMathSalary}
				mathTimeTypes={workerMathTime}
				onHide={() => setIsCreateWorker(false)}
				qualificationTypes={workerQualification}
				workerCategories={workerCategories}
				workerTypes={workerTypes}
				onCreated={() => window.location.reload()}
				jwt={jwt}
			/> : ''
		}
	</React.Fragment>
}

export default Workers
