import { useRef, useState } from 'react'
import { Alert, Button, Form, ListGroup } from 'react-bootstrap'
import { downloadExcel } from './SalaryAPI'
import WorkersModal from './WorkersModal'

export default function Excel({ isMobile, workers, workerCategories, jwt }) {
	const dateInput = useRef()
	const [validated, setValidated] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [selectedWorkers, setSelectedWorkers] = useState([])
	const [isEmptyList, setIsEmptyList] = useState(false)
	const [excelData, setExcelData] = useState({
		month: '',
		normalHours: 160,
		workers: [],
	})

	const handleModalShow = () => setShowModal(true)
	const handleWorkerSelect = workers => {
		setSelectedWorkers(workers)
		setShowModal(false)
		setExcelData({ ...excelData, workers: workers })
	}

	const downloadExcelFile = () => {
		const workersIds = excelData.workers.map(worker => worker.id)
		const data = { ...excelData, workers: workersIds }
		downloadExcel(jwt, data)
		setExcelData({ month: '', normalHours: 160, workers: [] })
		setSelectedWorkers([])
		setIsEmptyList(!isEmptyList)
		dateInput.current.value = ''
	}

	const handleSubmit = event => {
		event.preventDefault()
		if (dateInput.current.value === '') {
			return alert('Выберите дату')
		} else if (selectedWorkers.length <= 0) {
			return alert('Список сотрудников пуст')
		}

		const form = event.currentTarget
		if (form.checkValidity() === false) {
			event.stopPropagation()
		}
		setValidated(true)
		downloadExcelFile()
	}

	return (
		<div>
			<h1 style={{ textAlign: 'center' }}>Excel</h1>
			<Form onSubmit={handleSubmit}>
				<Form.Label htmlFor='fineDate'>Дата</Form.Label>
				<Form.Control
					ref={dateInput}
					type='date'
					id='fineDate'
					className='mb-3'
					onChange={e => setExcelData({ ...excelData, month: e.target.value })}
				/>
				<Form.Label
					htmlFor='normOfHours'
					onChange={e =>
						setExcelData({ ...excelData, normalHours: e.target.value })
					}
				>
					Норма часов
				</Form.Label>
				<Form.Control
					type='number'
					id='normOfHours'
					defaultValue={160}
					value={excelData.normalHours}
					onChange={e =>
						setExcelData({
							...excelData,
							normalHours: Number(e.target.value),
						})
					}
					className='mb-3'
				/>
				<WorkersModal
					isMobile={isMobile}
					categories={workerCategories}
					show={showModal}
					onHide={() => setShowModal(false)}
					workers={workers}
					onSelect={handleWorkerSelect}
					isEmptyList={isEmptyList}
				/>
				<div>
					<h3>Сотрудники:</h3>
					<Button className='mb-3' onClick={handleModalShow}>
						Редактировать
					</Button>
					<ListGroup>
						{selectedWorkers.length > 0 ? (
							<ul
								style={
									!isMobile ? { maxHeight: '450px', overflowY: 'auto' } : {}
								}
							>
								{selectedWorkers.map(worker => (
									<li
										className='mb-1'
										key={worker.id}
									>{`${worker.firstName} ${worker.secondName} ${worker.thirdName}`}</li>
								))}
							</ul>
						) : (
							<Alert variant='warning'>Здесь пусто...</Alert>
						)}
					</ListGroup>
				</div>
				<Button variant='success' style={{ width: '100%' }} type='submit'>
					Скачать
				</Button>
			</Form>
		</div>
	)
}
