import { useState } from "react";
import { createPull } from "../productWarehouseApi";
import { Button, Form, Modal } from "react-bootstrap";


export default function CreatePull({ id, show, onHide, onUpdate }) {

    const [count, setCount] = useState(0);
    const [message, setMessage] = useState('');
    const [isCoefficient, setIsCoefficient] = useState(false);

    const submitPull = async (e) => {

        e.preventDefault();

        const response = await createPull({
            productId: id,
            message: message,
            value: count,
            isCoefficient: isCoefficient
        });

        if (response && response.id > 0)
            onUpdate(count);
        else alert("Что-то пошло не так обратитесь в поддержку");
    }

    return <Modal show={show} onHide={() => onHide(false)} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Form onSubmit={(e) => submitPull(e)}>
            <Modal.Header closeButton>
                <Modal.Title>Скорректировать</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                    <Form.Label>Кол-во</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="100"
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput100">
                    <Form.Label>Сообщение</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="100"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </Form.Group>
                <Form.Check // prettier-ignore
                    type="checkbox"
                    id="default-checkbox"
                    label="Учитывать коэффициент"
                    checked={isCoefficient}
                    onChange={(e) => setIsCoefficient(e.target.checked)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(false)}>Отмена</Button>
                <Button variant="success" type="submit">Отправить</Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}