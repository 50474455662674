import { useState } from 'react'
import {
	Alert,
	Button,
	Container,
	Form,
	ListGroup,
	Modal,
	Stack,
} from 'react-bootstrap'
import {
	createFine,
	deleteUserFine,
	getUserFines,
	putUserFine,
} from './SalaryAPI'

const Worker = ({ workerData, isMobile, jwt }) => {
	const [isFineCreate, setIsFineCreate] = useState(false)
	const [isShowFines, setIsShowFines] = useState(false)
	const [isFineEdit, setIsFineEdit] = useState(false)
	const [fines, setFines] = useState([])
	const [validated, setValidated] = useState(false)
	const [selectedMonth, setSelectedMonth] = useState('')
	const [selectedFine, setSelectedFine] = useState(null)
	const [selectedFineOriginal, setSelectedFineOriginal] = useState(null)
	const [isLoaded, setIsLoaded] = useState(false)
	const [fineData, setFineData] = useState({
		workerId: workerData.id,
		message: '',
		date: '',
	})

	const fineGroup = () => {
		switch (workerData.countFine) {
			case 1:
				return 'success'
			case 2:
				return 'danger'
			default:
				return 'warning'
		}
	}

	const fineCreateFunction = () => {
		let currentDate = new Date().toISOString()
		setFineData(prevState => {
			const updatedData = { ...prevState, date: currentDate }
			createFine(jwt, updatedData)
			return {
				workerId: workerData.id,
				message: '',
				date: '',
			}
		})
	}

	const handleSubmit = () => {
		if (fineData.message) {
			fineCreateFunction()
			setIsFineCreate(false)
		} else {
			alert('Сообщение штрафа не может быть пустым!')
		}
	}

	const handleFineDelete = id => {
		deleteUserFine(jwt, id)
		setFines(fines.filter(fine => selectedFine.id !== fine.id))
		setIsFineEdit(false)
	}

	const handleFineEdit = () => {
		if (selectedFine.message.trim() == '') {
			alert('Сообщение штрафа не может быть пустым!')
		} else {
			if (selectedFine.message !== selectedFineOriginal.message) {
				putUserFine(jwt, selectedFine.id, { message: selectedFine.message })
				setFines(
					fines.map(fine => (selectedFine.id == fine.id ? selectedFine : fine))
				)
				setSelectedFine(null)
				setSelectedFineOriginal(null)
				setIsFineEdit(false)
			} else {
				setIsFineEdit(false)
			}
		}
	}

	const getFinesList = async () => {
		const [data, status] = await getUserFines(jwt, selectedMonth, workerData.id)
		setFines(await data)
		setIsLoaded(await status)
	}

	return (
		<Container>
			{isFineCreate ? (
				<Modal show={isFineCreate} onHide={() => setIsFineCreate(false)}>
					<Modal.Header closeButton>
						<h2>Выписать штраф</h2>
					</Modal.Header>
					<Modal.Body>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<Form.Label htmlFor='fineMessage'>Сообщение штрафа</Form.Label>
							<Form.Control
								required
								type='text'
								id='fineMessage'
								value={fineData.message}
								onChange={e =>
									setFineData({ ...fineData, message: e.target.value })
								}
								className='mb-3'
							/>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={() => setIsFineCreate(false)}>
							Закрыть
						</Button>
						<Button variant='primary' onClick={() => handleSubmit()}>
							Сохранить
						</Button>
					</Modal.Footer>
				</Modal>
			) : (
				''
			)}
			{selectedFine ? (
				<Form>
					<Modal show={isFineEdit}>
						<Modal.Header closeButton>Редактировать штраф</Modal.Header>
						<Modal.Body>
							<Form.Group className='mb-3'>
								<Form.Control
									as='textarea'
									rows={3}
									value={selectedFine.message}
									onChange={e =>
										setSelectedFine({
											...selectedFine,
											message: e.target.value,
										})
									}
								/>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={() => setIsFineEdit(false)}>
								Закрыть
							</Button>
							<Button variant='danger' onClick={() => handleFineDelete()}>
								Удалить
							</Button>
							<Button onClick={() => handleFineEdit(selectedFine.id)}>
								Сохранить
							</Button>
						</Modal.Footer>
					</Modal>
				</Form>
			) : (
				''
			)}
			{isShowFines ? (
				<Modal show={isShowFines} onHide={() => setIsShowFines(false)}>
					<Modal.Header closeButton className=''>
						<h2>Выберите месяц</h2>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Control
								required
								type='date'
								id='fineDate'
								className='mb-3'
								onChange={e => {
									setSelectedMonth(e.target.value)
									getFinesList()
								}}
							/>
						</Form>
						{fines && fines.length > 0 ? (
							fines.map(fine => (
								<ListGroup.Item key={fine.id} className='mb-2'>
									<p>{fine.message}</p>
									<Button
										variant='warning'
										onClick={() => {
											setSelectedFine(fine)
											setSelectedFineOriginal(fine)
											setIsFineEdit(true)
										}}
									>
										Редактировать
									</Button>
								</ListGroup.Item>
							))
						) : fines && isLoaded && fines.length === 0 ? (
							<ListGroup.Item>Штрафов нет</ListGroup.Item>
						) : (
							''
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={() => setIsShowFines(false)}>
							Закрыть
						</Button>
					</Modal.Footer>
				</Modal>
			) : (
				''
			)}

			<Alert variant={fineGroup()}>
				<Stack
					style={{ width: '100%', padding: '10px' }}
					gap={1}
					direction={isMobile ? 'vertical' : 'horizontal'}
				>
					<p style={{ whiteSpace: 'nowrap' }}>
						{`${workerData.secondName} ${workerData.firstName} ${workerData.thirdName}`}
					</p>
					<Stack gap={2} direction={isMobile ? 'vertical' : 'horizontal'}>
						<Button
							variant='warning'
							onClick={() => setIsShowFines(true)}
							style={{ whiteSpace: 'nowrap' }}
						>
							Редактировать штрафы
						</Button>
						<Button
							variant='danger'
							style={{ whiteSpace: 'nowrap' }}
							onClick={() => setIsFineCreate(true)}
						>
							Выписать штраф
						</Button>
					</Stack>
				</Stack>
			</Alert>
		</Container>
	)
}

export default Worker
